import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TechnicalExpertiseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view_all: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.expertise !== this.props.expertise) {
      this.setState({view_all: false});
    }
  }

  handleChange = () => {
    this.setState(prevState => ({
      view_all: !prevState.view_all,
    }));
  };

  render() {
    const {expertise} = this.props;
    const {view_all} = this.state;

    return (
      <div className="expertise-card">
        <div className="technology-container">
          {expertise.map(({label, iconSrc}, index) =>
            (index > 4 && view_all) || index <= 4 ? (
              <div className="technology-card" key={label}>
                <img src={iconSrc} height="50px" alt={label} />
                <p className="mt-2">{label}</p>
              </div>
            ) : null
          )}
        </div>
        {expertise.length > 6 && (
          <div className="col-12 text-center mt-5 p-0">
            <button onClick={this.handleChange}>
              <img
                className="col-12"
                src={
                  require('../../../assets/images/expertise/technical-expertise/view-more-icon.svg')
                    .default
                }
                height="10px"
                alt="View more icon"
                style={view_all ? {transform: 'rotateX(180deg)'} : {}}
              />
              {!view_all ? 'View more' : 'View less'}
            </button>
          </div>
        )}
      </div>
    );
  }
}

TechnicalExpertiseCard.propTypes = {
  expertise: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      iconSrc: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default TechnicalExpertiseCard;
