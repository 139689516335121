import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import * as classes from './ClientTestimonialCard.module.css';

function ClientTestimonialCard({category, review, name, designation, imgSrc}) {
  return (
    <article className={classes.card}>
      <img
        className={classes.icon}
        src={require('../../../assets/images/expertise/client-testimonial/commas.svg').default}
        alt={name}
      />
      <p className={`${classes.review}`}>{review}</p>
      <p className={`${classes.name}`}>{name}</p>
      <p className={`${classes.designation}`}>{designation}</p>
      <img className={classes.img} src={imgSrc} alt={name} />
    </article>
  );
}

ClientTestimonialCard.propTypes = {
  category: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default ClientTestimonialCard;
