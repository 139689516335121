import React, {useEffect, useState} from 'react';
import {MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView} from 'mdbreact';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai';
import ClientTestimonialCard from '../ClientTestimonialCard/ClientTestimonialCard';
import ArrowRight from '../../../assets/images/expertise/client-testimonial/arrow-right.svg';
import ArrowLeft from '../../../assets/images/expertise/client-testimonial/arrow-left.svg';
import './ClientTestimonialCarousel.css';

const ClientTestimonialCarousel = ({testimonials, selectedCategory}) => {
  const [carouselKey, setCarouselKey] = useState(0);
  useEffect(() => {
    // Restart the carousel when the selected category changes
    setCarouselKey(prevKey => prevKey + 1);
  }, [selectedCategory]);
  const customStyles = `

  .carousel .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e6e7ee !important;
    border: none;
  }
    .carousel .carousel-indicators li  {
      background-color: white;
    }

    .carousel-control-prev,
    .carousel-control-next {
      top: -23%;
      width: 0%;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-image: url("${ArrowLeft}");
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;
    }

    .carousel-control-next-icon {
      background-image: url("${ArrowRight}");
    }
  `;

  testimonials.sort((a, b) => {
    if (a.category === selectedCategory && b.category !== selectedCategory) {
      return -1; // Move a to the top
    }
    if (a.category !== selectedCategory && b.category === selectedCategory) {
      return 1; // Move b to the top
    }
    return 0; // Maintain the current order
  });
  return (
    <>
      <style>{customStyles}</style>
      <MDBCarousel
        key={carouselKey}
        activeItem={1}
        length={testimonials.length}
        showControls
        ride={false}
        showIndicators
        className="carousel"
        interval={false}
        slide={false}
        wrap={false}
      >
        <MDBCarouselInner>
          {testimonials.map((testimonial, index) => (
            <MDBCarouselItem key={index + 1} itemId={index + 1}>
              <MDBView>
                <ClientTestimonialCard {...testimonial} />
              </MDBView>
            </MDBCarouselItem>
          ))}
        </MDBCarouselInner>
      </MDBCarousel>
    </>
  );
};

export default ClientTestimonialCarousel;
