import React from 'react';
import PropTypes from 'prop-types';

function TechnicalServices({services}) {
  return (
    <div className="services-container">
      {services.map(label => (
        <div className="services-card text-center" key={label}>
          {label}
        </div>
      ))}
    </div>
  );
}

TechnicalServices.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      iconSrc: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default TechnicalServices;
